import React from 'react'
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import Courses from './Courses';

const UniversityDetailView = () => {
  return (
    <div>
           <div className='flex flex-col md:flex-row justify-between gap-10 bg-white px-10'>
                        <div className='md:w-1/2'>
                            <div className='md:pt-16 pb-2 pt-2'><span className='text-[14px] pt-sans-bold '>Description</span></div>
                            <div class="border-dotted-b   flex items-center justify-center"></div>
                            <div className='text-[12px] pt-sans-regular py-6 '>

                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore delectus distinctio quidem, alias molestias asperiores beatae molestiae, est pariatur magnam similique temporibus. Velit laudantium voluptatibus culpa quaerat iusto! At, amet.
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore delectus distinctio quidem, alias molestias asperiores beatae molestiae, est pariatur magnam similique temporibus. Velit laudantium voluptatibus culpa quaerat iusto! At, amet.


                            </div>
                        </div>
                        <div className='md:w-1/2'>
                            <div className='md:pt-16 pb-2 pt-2'><span className='text-[14px] pt-sans-bold '>Admission and Enquiry</span></div>
                            <div class="border-dotted-b   flex items-center justify-center"></div>
                            <div className='flex flex-col md:flex-row gap-4 py-6'>
                                <div className='flex gap-2'>
                                    <div>
                                        logo
                                    </div>
                                    <div>
                                        <div className='text-[14px] pt-sans-bold'>Agency Name</div>
                                        <div className='text-[12px]'>Location : <span className='pt-sans-bold text-[12px] '>Kochi,Kerala</span></div>
                                    </div>
                                </div>
                                <div className='bg-green-400  pt-sans-bold rounded-full flex items-center justify-center px-4 py-2 text-center text-[12px]'>
                                    Contact Agency
                                </div>

                            </div>
                        </div>
                    </div>
                    






    </div>
  )
}

export default UniversityDetailView