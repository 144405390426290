
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import UniversityDropdown from '../dropDown/UniversityDropdown';
import tokenInstance from '../axiosInstance/tokenInstance';

const Filters = () => {
    const [formData, setFormData] = useState({
        marks: 50,
        fees_from: 0,
        fees_to: 10,
        location: "",
        university_type: "",
        benefits: ""
    });

    const [universityStatus, setUniversityStatus] = useState('');
    const [universityOpen, setUniversityOpen] = useState(null);
    const [filterId, setFilterId] = useState(null);

    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/view-student-filter/');
                // Assuming that response.data is an array and you want to get the first item or a specific item
                const filterData = response.data; // Adjust this logic based on how you want to get the specific filter
                if (filterData) {
                    setFilterId(filterData.id); // Set the ID for update
                    setFormData({
                        marks: filterData.marks,
                        fees_from: filterData.fees_from,
                        fees_to: filterData.fees_to,
                        location: filterData.location || "",
                        university_type: filterData.university_type || "",
                        benefits: filterData.benefits || ""
                    });
                    setUniversityStatus(filterData.university_type || "");
                }
            } catch (error) {
                console.error('Error fetching filter data:', error);
            }
        };

        fetchFilterData();
    }, []);

    const handleMarksChange = (event, newValue) => {
        setFormData({ ...formData, marks: newValue });
    };

    const handleFeesChange = (event, newValue) => {
        setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] });
    };

    const UniversityMethod = (option) => {
        setUniversityStatus(option);
        setFormData({ ...formData, university_type: option });
    };

    const toggleUniversity = () => {
        setUniversityOpen((prevState) => !prevState);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSaveFilter = async () => {
        if (!filterId) {
            console.error('Filter ID not found');
            return;
        }

        const payload = {
            marks: formData.marks,
            fees_from: formData.fees_from,
            fees_to: formData.fees_to,
            location: formData.location,
            university_type: formData.university_type,
            benefits: formData.benefits
        };

        try {
            const response = await tokenInstance.patch(`home/api/v1/update-student-filter/${filterId}/`, payload);
            console.log('Filter updated:', response.data);
        } catch (error) {
            console.error('Error updating filter:', error);
        }
    };

    return (
        <div>
            <div className='bg-white shadow-md px-4 py-6 w-full '>
                <div className='pb-4'>
                    <div>
                        <div className='text-[13px] pt-sans-bold pb-2'>Your Mark in class 12th</div>
                    </div>
                    <Box  >
                        <Slider
                            defaultValue={formData.marks}
                            aria-label="Marks"
                            valueLabelDisplay="auto"
                            sx={{
                                color: 'black',
                                '& .MuiSlider-thumb': {
                                    borderRadius: '50%',
                                    backgroundColor: 'black',
                                    border: '2px solid black',
                                },
                                '& .MuiSlider-track': {
                                    backgroundColor: 'black',
                                },
                                '& .MuiSlider-rail': {
                                    backgroundColor: '#bfbfbf',
                                },
                                '& .MuiSlider-valueLabel': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '4px',
                                },
                            }}
                            onChange={handleMarksChange}
                            value={formData.marks}
                        />
                    </Box>
                    <div className='flex justify-between '>
                        <div className='pt-sans-regular text-[12px]'>55%</div>
                        <div className='pt-sans-bold text-[12px] '>63%</div>
                        <div className='pt-sans-regular text-[12px]'>100%</div>
                    </div>
                </div>

                <div className='py-4'>
                    <div className='text-[13px] pt-sans-bold pb-2'>Yearly fees you can afford (INR)</div>
                    <Box>
                        <Slider
                            value={[formData.fees_from, formData.fees_to]}
                            onChange={handleFeesChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={20}
                            sx={{
                                color: 'black',
                                '& .MuiSlider-thumb': {
                                    borderRadius: '50%',
                                    backgroundColor: 'black',
                                },
                                '& .MuiSlider-track': {
                                    backgroundColor: 'black',
                                },
                                '& .MuiSlider-rail': {
                                    backgroundColor: 'black',
                                },
                                '& .MuiSlider-valueLabel': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    borderRadius: '4px',
                                },
                            }}
                        />
                    </Box>
                    <div className='flex justify-between '>
                        <div className='pt-sans-regular text-[12px]'>0 lakhs</div>
                        <div className='pt-sans-bold text-[12px] '>3 lakhs-6 lakhs</div>
                        <div className='pt-sans-regular text-[12px]'>20 lakhs</div>
                    </div>
                </div>

                <div className='flex flex-col gap-2'>
                    <div>
                        <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            className="border border-gray-300 rounded-md p-1.5 w-full text-[12px] text-black outline-none px-4"
                            value={formData.location}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <UniversityDropdown
                            options={['Private', 'Public']}
                            selectedOption={universityStatus}
                            onSelect={UniversityMethod}
                            toggleDropdown={toggleUniversity}
                            universityOpen={universityOpen}
                            setUniversityOpen={setUniversityOpen}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="benefits"
                            placeholder="Benefits"
                            className="border border-gray-300 rounded-md p-1.5 w-full text-[12px] px-4 outline-none"
                            value={formData.benefits}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className='flex justify-end pt-4'>
                    <button
                        onClick={handleSaveFilter}
                        className='bg-green-500 px-2.5 text-[11px] pt-sans-bold rounded-full text-center py-1.5 '
                    >
                        Save Filter
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Filters;
 

