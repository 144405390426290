import React from 'react'

const Tabs = ({ setSearchQuery, searchQuery }) => {

    const handleSearchChange = (e) => { 
        setSearchQuery(e.target.value);
    };
    return (
        <div>
            <div className="px-4 md:px-20">
                <div className=" py-4">
                    <div className="text-[18px] py-2 text-black pt-sans-bold">
                        Search A Country you wish to Study
                    </div>

                    <input
                        type="text"
                        className="border border-none rounded-sm bg-[#EBEBEB] text-[12px] pt-sans-regular px-4 pr-4 py-2 outline-none w-full"
                        placeholder="Search a Country"
                        value={searchQuery}
                        onChange={handleSearchChange}

                    />

                </div>
            </div>




        </div>
    )
}

export default Tabs