
import Filters from '../filters/Filters'
import axiosInstance from '../axiosInstance/axiosInstance'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';




const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);
    const navigate = useNavigate();
    const fetchCourses = (url) => {
        axiosInstance.get(url)
            .then(response => {
                setCourses(response.data.results); // Set the courses from response
                setNextPage(response.data.next); // Set the next page URL
                setPreviousPage(response.data.previous); // Set the previous page URL
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    };
    useEffect(() => {
        // Initial fetch for the first page
        fetchCourses('home/api/v1/student-course-list/?limit=4');
    }, []);
    const handleNextPage = () => {
        if (nextPage) {
            fetchCourses(nextPage);
            setCurrentPage(currentPage + 1); // Increment page count
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchCourses(previousPage);
            setCurrentPage(currentPage - 1); // Decrement page count
        }
    };
    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        if (tab === 'courses') {
            navigate('/course'); // Navigate to /course when "Courses" is clicked
        } else if (tab === 'university') {
            navigate('/universities'); // Navigate to /universities when "University" is clicked
        }
    };






    return (
        <div>
            <div>


                <div className='px-8 md:px-20 '>


                   
                   





                    <div className='flex  gap-6 py-10 flex-col lg:flex-row'>

                        <div>
                            <Filters />
                        </div>
                        <div className='flex flex-col gap-6'>
                            <div className='flex gap-6'>
                            <div
                                        className={`text-[12px] pt-sans-regular cursor-pointer ${location.pathname === '/course' ? 'pt-sans-bold' : ''}`}
                                        onClick={() => handleTabChange('courses')} // Trigger tab change and navigation
                                    >
                                        Courses
                                    </div>
                                    <div
                                        className={`text-[12px] pt-sans-regular cursor-pointer ${location.pathname === '/universities' ? 'pt-sans-bold' : ''}`}
                                        onClick={() => handleTabChange('university')} // Navigate to /universities when clicked
                                    >
                                        University
                                    </div>

                                <div className='text-[12px] pt-sans-regular'>Scholarships</div>
                            </div>
                            <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2 gap-4   ">
                                {
                                    courses.map(course => (
                                        <div key={course.id} className="bg-white shadow-md w-full  md:w-full lg:w-[800px] ">
                                            <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                                <div className="flex justify-between">
                                                    <div>
                                                        <div className='flex items-center gap-2'>
                                                            <div className='pt-sans-bold text-xl'>{course.name}</div><div className='pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1'>Norvel Recommended</div>


                                                        </div>
                                                        <div className='pt-sans-regular text-[12px]'>
                                                            {course.education_level} / {course.course_duration} years /
                                                            <span className='pt-sans-bold'> {course.fee_per_year} per year / </span>
                                                            <span
                                                                style={{
                                                                    background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(235,112,35,1) 100%)',
                                                                    WebkitBackgroundClip: 'text',
                                                                    WebkitTextFillColor: 'transparent'
                                                                }}
                                                                className='text-[11px] pt-sans-bold'>
                                                                {course.language ? ' IELTS Required' : ''}

                                                            </span>
                                                        </div>


                                                    </div>
                                                    <div className='pt-4 hidden md:block'>
                                                        <div>
                                                            <div className='pt-sans-regular text-[11px]'>Living Expense:12Lakhs</div>
                                                            <div className='text-[10px]'>{course.POF_mode}</div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="border-dotted-b   flex items-center justify-center blcok md:hidden"></div>

                                                <div>
                                                    <div className="flex  justify-between item-center">
                                                        <div className='flex gap-4'>
                                                            <div>logo</div>
                                                            <div>
                                                                <div className='text-[14px] pt-sans-bold '>{course.university?.name}</div>
                                                                <div className='flex gap-2'>
                                                                    <div className='text-[12px]'>Location : <span className='pt-sans-bold'>sydney</span></div>
                                                                    <div className='text-[12px]'>Type : <span className='pt-sans-bold'>private</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="flex gap-1 hidden md:block">
                                                                {/* <div>Country Logo</div> */}
                                                                <div className='pt-sans-bold text-[10px]'>{course.country?.name}</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>





                                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                                <div className="flex justify-between">
                                                    <div className="flex gap-2 flex-wrap ">
                                                        {course.tags.map(tag => (
                                                            <div key={tag.id}>
                                                                <span className='inter-bold text-[10px] bg-green-100 px-4 rounded-full py-2'>{tag.tag}</span>
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                            <div className='flex gap-2 py-6 items-center justify-center'>
                                {previousPage && (
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={!previousPage}
                                        className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!previousPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        Back
                                    </button>
                                )}


                                <div className='text-black text-[12px] pt-sans-bold'>{currentPage}</div>
                                {nextPage && (
                                    <button
                                        onClick={handleNextPage}
                                        disabled={!nextPage}
                                        className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        Next
                                    </button>

                                )}


                            </div>
                        </div>






                    </div>








                </div>
            </div>
        </div>
    )
}

export default Courses