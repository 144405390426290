


// import axios from 'axios';
// export  const baseURL = 'https://norvelbackend.site/'
// const axiosInstance = axios.create({
//   baseURL: baseURL,
//   timeout: 10000,
// });


// let isRefreshing = false;
// let refreshSubscribers = [];

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = getTokenFromLocalStorage();
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.request.use(config => {
//   // Exclude authorization header for login API
//   if (config.url.includes('/login')) {
//     console.log("login url");
//     delete config.headers.Authorization;
//     localStorage.removeItem('token');
//   }
//   return config;
// });

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const { config, response: { status } } = error;
//     const originalRequest = config;

//     if (status === 401) {
//       removeTokenFromLocalStorage();
//       if (!isRefreshing) {
//         isRefreshing = true;
//         try {
//           const newToken = await refreshAccessToken();
//           originalRequest.headers.Authorization = `Bearer ${newToken}`;
//           resolveRefreshedRequests(newToken);
//           return axios(originalRequest);
//         } catch (error) {
//           handleTokenRefreshFailure(error);
//           return Promise.reject(error);
//         } finally {
//           isRefreshing = false;
//         }
//       } else {
//         return waitForTokenRefresh(originalRequest);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// function getTokenFromLocalStorage() {
//   console.log("token", localStorage.getItem('token'))
//   return localStorage.getItem('token');
// }

// function removeTokenFromLocalStorage() {
//   localStorage.removeItem('token');
// }

// async function refreshAccessToken() {
//   const refreshToken = getRefreshTokenFromLocalStorage();
//   const response = await axios.post('/account/api/token/refresh/', {
//     refreshToken: refreshToken,
//   });
//   const newToken = response.data.token;
//   localStorage.setItem('token', newToken);
//   return newToken;
// }

// function getRefreshTokenFromLocalStorage() {
//   return localStorage.getItem('refreshToken');
// }

// function resolveRefreshedRequests(newToken) {
//   refreshSubscribers.forEach(callback => callback(newToken));
//   refreshSubscribers = [];
// }

// function waitForTokenRefresh(originalRequest) {
//   return new Promise((resolve) => {
//     refreshSubscribers.push((token) => {
//       originalRequest.headers.Authorization = `Bearer ${token}`;
//       resolve(axios(originalRequest));
//     });
//   });
// }

// function handleTokenRefreshFailure(error) {
//   console.error('Failed to refresh token:', error);
//   // window.location.href = '/login'; // Redirect to login page on token refresh failure
// }

// export default axiosInstance;

import axios from 'axios';

export const baseURL = 'https://norvelbackend.site/';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

// Request interceptor to remove authorization header for login API
axiosInstance.interceptors.request.use(config => {
  if (config.url.includes('/login')) {
    console.log("login url");
    delete config.headers.Authorization;
    localStorage.removeItem('token');
  }
  return config;
});

// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response: { status } } = error;

    if (status === 401) {
      // Handle unauthorized error (401) here if needed
      console.error('Unauthorized access - perhaps redirect to login?');
      // window.location.href = '/login'; // Uncomment this line if you want to redirect to login page
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

