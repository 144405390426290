import React, { useEffect, useState, useCallback } from 'react';
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import axiosInstance from '../components/axiosInstance/axiosInstance';

import Countries from '../components/countries/Countries'
import Tabs from '../components/tabs/Tabs';
import { ITEMS_PER_PAGE } from '../config/config';

const Home = () => {
  const [countries, setCountries] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [count, setCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [educationTypeCounts, setEducationTypeCounts] = useState([]);
    const [selectedEducationType, setSelectedEducationType] = useState('');

  


    const fetchCountries = async (url, educationType = '') => {
      setLoading(true);
      try {
          // Construct the URL with education type if provided
          const response = await axiosInstance.get(`${url}&education_type=${educationType}`);
          
          setCountries(response.data.results);
          setNextPage(response.data.next);
          setPreviousPage(response.data.previous);
          setCount(response.data.count);
          setTotalPages(Math.ceil(response.data.count / ITEMS_PER_PAGE));
          setEducationTypeCounts(response.data.education_type_count);
      } catch (error) {
          console.error('Error fetching country data:', error);
      } finally {
          setLoading(false);
      }
  };
  
  useEffect(() => {
    fetchCountries(`home/api/v1/country-list/?limit=9&search=${searchQuery}`, selectedEducationType);
}, [searchQuery, selectedEducationType]);


  return (
    <div>
      <div 
      >
        <Header setDropdownVisible={setDropdownVisible} isDropdownVisible={isDropdownVisible}/>


        <Center isDropdownVisible={isDropdownVisible} setDropdownVisible={setDropdownVisible} />
       
      </div>
      <Tabs searchQuery={searchQuery} setSearchQuery={setSearchQuery}  />  
     
      
      <div>
      <Countries totalPages={totalPages}  previousPage={previousPage} setCurrentPage={setCurrentPage} nextPage={nextPage} currentPage={currentPage} countries={countries} fetchCountries={fetchCountries} count={count} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} loading={loading} />

      </div>

      <div className=''>
        <Footer />
      </div>





    </div>
  )
}

export default Home
