import React, { useState } from 'react'

const CourseFilter = ({ setSelectedEducationType, selectedEducationType, setEducationTypeCounts, educationTypeCounts

}) => {

    const [filterApplied, setFilterApplied] = useState(false);
    const handleTabClick = (name) => {
        if (selectedEducationType === name) {
            // Toggle the filter off if the same type is clicked
            setSelectedEducationType();
            setFilterApplied(false);
        } else {
            // Apply the filter
            setSelectedEducationType(name);
            setFilterApplied(true);
        }
    };



    return (
        <div>

            <div className='py-4 block md:hidden'>

                <div className='block md:hidden mb-4'>
                    <div className='flex flex-col gap-4 md:gap-0  bg-gray-600 md:flex-row md:justify-between items-center overflow-x-auto '>
                        <div className='flex flex-nowrap '>
                            <div className="flex gap-4 text-white bg-gray-600 pl-8 pt-sans-bold text-[13px] items-center whitespace-nowrap">
                                {educationTypeCounts.map((eduType) => (
                                    <div
                                        key={eduType.name}
                                        className={`cursor-pointer text-[12px] md:text-[13px] py-2.5  px-2 ${selectedEducationType === eduType.name ? ' font-bold border-b-4 border-green-600' : 'text-white'}`}
                                        onClick={() => handleTabClick(eduType.name)}
                                    >
                                        {eduType.name} ({eduType.count})

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block">
                <div className="flex flex-row gap-4 bg-black px-1 w-[420px] rounded-full  py-1 items-center my-4">
                    {educationTypeCounts.map((eduType) => (
                        <div
                            key={eduType.name}
                            className={`text-[12px] py-2 px-2 whitespace-nowrap cursor-pointer transition-all duration-200 ${selectedEducationType === eduType.name
                                ? 'bg-green-400 rounded-full text-black font-semibold px-4'
                                : 'text-white font-normal'
                                }`}
                            onClick={() => handleTabClick(eduType.name)}
                        >
                            {eduType.name} ({eduType.count})
                        </div>
                    ))}
                </div>
            </div>




        </div>
    )
}

export default CourseFilter