import React from 'react'
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Courses from '../components/courses/Courses'
import Footer from '../components/footer/Footer'

const Course = () => {
    return (
        <div>
            <Header />
            <Center />
            <Courses />
            <Footer />




        </div>
    )
}

export default Course