import React from 'react'
import Universities from '../components/courses/Universities'
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'

const University = () => {
  return (
    <div>
      <Header />
      <Center />
      <Universities />
      <Footer />
    </div>
  )
}

export default University