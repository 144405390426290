import React, { useEffect, useState, useCallback } from 'react';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import CourseFilter from '../tabs/CourseFilter';

import { useNavigate } from 'react-router-dom';



const Countries = ({ totalPages, previousPage, setCurrentPage, nextPage, currentPage, countries, fetchCountries, setEducationTypeCounts, educationTypeCounts, count, setSelectedEducationType, selectedEducationType, loading }) => {
    const [cardCount, setCardCount] = useState(0);


    // Calculate the number of shimmer placeholders based on the screen size
    const calculateCardCount = () => {
        const width = window.innerWidth;
        if (width >= 1024) {
            // lg screens
            return 3 * 3; // 3 rows x 3 columns
        } else if (width >= 768) {
            // md screens
            return 2 * 3; // 2 rows x 3 columns
        } else {
            // mobile screens
            return 1 * 3; // 1 row x 3 columns
        }
    };

    useEffect(() => {
        setCardCount(calculateCardCount());

        // Update card count on window resize
        const handleResize = () => {
            setCardCount(calculateCardCount());
        };
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const navigate = useNavigate(); 
    const handleCountryClick = () => {
        navigate('/course'); // Navigate to the /course page
    };




    const handleNextPage = () => {
        if (nextPage) {
            fetchCountries(nextPage, selectedEducationType);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handleBackPage = () => {
        if (previousPage) {
            fetchCountries(previousPage, selectedEducationType);
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleEducationTypeChange = (e) => {
        setSelectedEducationType(e.target.value);
    };


    if (loading) {
        return (
            <div className='md:px-16'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10'>
                    {Array.from({ length: cardCount }).map((_, index) => (
                        <div key={index}
                        >
                            <ShimmerSimpleGallery row={1} col={1} card imageHeight={120} caption />



                        </div>
                    ))}
                </div>
                <div className='flex gap-2 py-6 items-center justify-center'>
                    {previousPage && (
                        <button
                            onClick={handleBackPage}
                            className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'
                        >
                            BACK
                        </button>
                    )}
                    <div className='text-black text-[12px] pt-sans-bold font-bold'>
                        {currentPage} of {totalPages}
                    </div>
                    {nextPage && (
                        <button
                            onClick={handleNextPage}
                            className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'
                        >
                            NEXT
                        </button>
                    )}
                </div>
            </div>
        );
    }





    return (
        <div>
            <div className='bg-white'>
                <div className='px-4 md:px-20'>

                    <div className='text-[22px] lg:text-[28px] pt-sans-bold font-bold pb-2'>Please Select a Country for Enquiry</div>
                    <div className='text-[#4C6267] pt-sans-bold text-[13px] '>
                        {count} Countries Are Now Open for Admissions
                    </div>
                </div>
            </div>
            <div className='md:px-16'>
                <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />





                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-4">
                    {countries.map((country, index) => (
                        <div key={index} className="block" onClick={handleCountryClick} style={{ cursor: 'pointer' }}>
                            <div className="px-4 flex flex-col justify-between gap-4">
                                <div>
                                    <img src={country.image || 'Example.png'} alt={country.name} />
                                </div>

                                <div className="">
                                    <div className='flex gap-2 flex-wrap'>
                                        {country.usps.map((usp, uspIndex) => (
                                            <div key={uspIndex} className=' pt-sans-bold text-[12px] bg-white px-4 py-1 rounded-full flex justify-center border border-1 border-gray-200'>
                                                {usp.name}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='flex flex-col py-2'>
                                            <div className='flex items-center flex-col'>
                                                <div className='flex  gap-2 items-center'>
                                                    <div className='flex flex-col '>
                                                        <div className='w-6 h-6 rounded-full'>
                                                            <img className='w-full h-full rounded-full' src={country.flag || 'default-image.png'} alt='Country' />

                                                        </div>


                                                    </div>

                                                    <div className='flex flex-col'>
                                                        <div className='inter-bold font-bold text-xl'>{country.usps && country.usps.length > 0 ? country.usps[0].country.name : 'Unknown Country'}</div>
                                                        <div className='text-[13px] pt-sans-regular '>Fees start at {country.minimum_fee_per_year}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <div className='text-md inter-bold font-bold'>{country.rank}</div>
                                            <div className='red-hat-display2 text-[10px]'>Rank</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='flex gap-2 py-6 items-center justify-center'>
                    {previousPage && (
                        <button onClick={handleBackPage} className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'>
                            BACK
                        </button>
                    )}
                    <div className='text-black text-[12px] pt-sans-bold font-bold'>{currentPage} of {totalPages}</div>
                    {nextPage && (
                        <button onClick={handleNextPage} className='text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold font-bold'>
                            NEXT
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Countries;
